import React, { useState, useEffect } from "react";
import axios from "axios";
import Btn from "./Button";
import { TimelineMax, Power0 } from "gsap/gsap-core";
const apiURL = process.env.REACT_APP_API_URL;

const Hero = () => {
  const [heroData, setHeroData] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiURL}hero`)
      .then((res) => {
        setHeroData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    var tl = new TimelineMax({ repeat: -1, yoyo: true });
    tl.fromTo(
      "#hero img",
      3,
      { ease: Power0.easeNone, y: 10 },
      { ease: Power0.easeNone, y: -10 }
    );
  }, []);

  return (
    <div id="hero">
      <div className="row">
        <div className="col">
          <h1 dangerouslySetInnerHTML={{ __html: heroData.heroTitle }} />
          <p>{heroData.heroContent}</p>
          <Btn btnType="primary" linkTo={heroData.heroBtnUrl}>
            {heroData.heroBtnText}
          </Btn>
        </div>
        <div className="img-wrapper">
          <img
            src={heroData.heroImg}
            alt={heroData.heroTitle}
            className="full-size-img full-size-img-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
