import React, { useState } from "react";

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState("Send");

  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, email, message } = e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    console.log(conFom);
  };

  return (
    <div className="form-container">
      <h3>Contact Now</h3>
      <h2>Let’s Work Together.</h2>
      <p>If you’ve got a project in mind, let’s talk!</p>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <input type="text" id="name" required placeholder="Full Name" />
        </div>
        <div className="form-control">
          <input type="email" id="email" required placeholder="Email" />
        </div>
        <div className="form-control">
          <textarea id="message" required placeholder="Message.." rows={8} />
        </div>
        <div className="form-control">
          <button className="btn btn-primary" type="submit">
            <span>{formStatus}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
