import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/fontawesome-free-solid";
const ProjectCard = (props) => {
  library.add(faExternalLinkAlt);
  let item = props.item;
  let imgUrl = item.image;
  let categories = item.project_category;
  categories = categories.map((item) => item.name);
  let title = item.title;
  let projectExternalLink = item.link;
  console.log(props);
  return (
    <>
      <div className="project-card" data-id={item.id}>
        <div
          className="project-card-image"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        ></div>
        <div className="project-card-content">
          <ul>
            {categories.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          <h3>{title}</h3>
          <a
            href={projectExternalLink}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-xs"
          >
            View Project <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
