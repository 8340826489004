import React, { useState, useEffect } from "react";
import axios from "axios";
import Btn from "./Button";
import SocialMedia from "./SocialMedia";

const apiURL = process.env.REACT_APP_API_URL;
const home_url = process.env.REACT_APP_HOME_URL;

const Header = () => {
  const [headerData, setHeaderData] = useState([]);
  const [headerClassName, setHeaderClassName] = useState("");

  useEffect(() => {
    axios
      .get(`${apiURL}header`)
      .then((res) => {
        setHeaderData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHeaderClassName("sticky");
    } else {
      setHeaderClassName("");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header id="masthead" className={headerClassName}>
        <a href={home_url} className="logo-wrapper d-block">
          <img
            src={headerData.logo_url}
            alt={headerData.site_title}
            className="logo full-size-img full-size-img-contain"
          />
        </a>
        <ul className="main-menu">
          {headerData.menu_items &&
            headerData.menu_items.map((item) => (
              <li key={item.id}>
                <a className={item.classes} href={item.url}>
                  {item.title}
                </a>
              </li>
            ))}
        </ul>
        <div className="social-media-wrapper">
          <SocialMedia />
        </div>
      </header>
    </>
  );
};

export default Header;
