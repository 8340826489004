import React, { useEffect } from "react";
import { gsap } from "gsap";
import { TweenMax, Linear } from "gsap/gsap-core";

const Particles = () => {
  useEffect(() => {
    gsap.registerPlugin(TweenMax);
    TweenMax.to(document.querySelector(".ani1img"), 20, {
      rotation: 360,
      top: 600,
      right: 40,
      scale: 2,
      repeat: -1,
      ease: Linear.easeNone,
      yoyo: true,
    });

    TweenMax.fromTo(
      document.querySelector(".ani2img"),
      20,
      {
        right: 0,
        scale: 0.5,
      },
      {
        rotation: 360,
        top: 600,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani3img"),
      20,
      {
        left: 0,
        top: 500,
        scale: 0.5,
      },
      {
        rotation: 360,
        left: 600,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani4img"),
      20,
      {
        right: 0,
        top: 900,
        scale: 0.5,
      },
      {
        rotation: 360,
        right: 600,
        top: 0,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani5img"),
      20,
      {
        left: 0,
        top: 900,
        scale: 0.5,
      },
      {
        rotation: 360,
        left: 600,
        top: 0,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );

    TweenMax.fromTo(
      document.querySelector(".ani6img"),
      20,
      {
        right: 0,
        top: 500,
        scale: 0.5,
      },
      {
        rotation: 360,
        right: 600,
        top: 900,
        scale: 1,
        repeat: -1,
        ease: Linear.easeNone,
        yoyo: true,
      }
    );
  }, []);

  return (
    <>
      <div className="animatedparticles">
        <img
          src="https://ann-designs.uk/wp-content/themes/dsk-bt/images/1.png"
          className="ani1img"
          alt="image1"
        />
        <img
          src="https://ann-designs.uk/wp-content/themes/dsk-bt/images/2.png"
          className="ani2img"
          alt="image2"
        />
        <img
          src="https://ann-designs.uk/wp-content/themes/dsk-bt/images/3.png"
          className="ani3img"
          alt="image3"
        />
        <img
          src="https://ann-designs.uk/wp-content/themes/dsk-bt/images/4.png"
          className="ani4img"
          alt="image4"
        />
        <img
          src="https://ann-designs.uk/wp-content/themes/dsk-bt/images/5.png"
          className="ani5img"
          alt="image5"
        />
        <img
          src="https://ann-designs.uk/wp-content/themes/dsk-bt/images/6.png"
          className="ani6img"
          alt="image6"
        />
      </div>
    </>
  );
};

export default Particles;
