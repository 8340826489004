import React, { useState, useEffect } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const About = () => {
  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    axios
      .get(`${apiURL}about`)
      .then((res) => {
        setAboutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div id="about">
      <div className="row">
        <div className="col">
          <h2>{aboutData.aboutTitle}</h2>
          <p>{aboutData.aboutContent}</p>
          <div className="skills-wrapper">
            <h3>{aboutData.aboutSkillsTitle}</h3>
            <ul>
              {aboutData.mySkills &&
                aboutData.mySkills.map((item) => (
                  <li key={item.id}>
                    <img
                      src={item.skillImage}
                      alt={item.skillTitle}
                      className="full-size-img full-size-img-contain"
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="video-wrapper">
          <video
            src={aboutData.aboutVideo}
            autoPlay
            loop
            muted
            controls={false}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
