import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const MyExperience = () => {
  const [experienceData, setExperienceData] = useState([]);
  useEffect(() => {
    axios
      .get(`${apiURL}experience`)
      .then((res) => {
        setExperienceData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="timeline-wrapper" id="my-experience">
      <h2>My Experience</h2>
      <Timeline position="alternate">
        {experienceData &&
          experienceData.map((item) => (
            <TimelineItem key={item.id}>
              <TimelineOppositeContent>
                <p>{item.experienceDate}</p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="content-wrapper">
                  <img src={item.experienceImg} alt={item.experienceTitle} />
                  <div className="content">
                    <h3>{item.experienceTitle}</h3>
                    <p>{item.experienceContent}</p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </div>
  );
};

export default MyExperience;
