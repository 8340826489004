import React from "react";

const Btn = (props) => {
  return (
    <a href={props.linkTo} className={`btn btn-${props.btnType}`}>
      <span>{props.children}</span>
      <span className="gradient"></span>
    </a>
  );
};

export default Btn;
