import React, { useState, useEffect } from "react";
import axios from "axios";
import ProjectCard from "./ProjectCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/fontawesome-free-solid";

const apiURL = process.env.REACT_APP_API_URL;

const ProjectsFilter = () => {
  library.add(faSpinner);
  //get projects from api for all categories
  const [projectsData, setProjectsData] = useState([]);
  const [projectsCategoriesData, setProjectsCategoriesData] = useState([]);
  useEffect(() => {
    axios
      .get(`${apiURL}projects`, { params: { filter_category: "all" } })
      .then((res) => {
        setProjectsData(res.data.projects);
        setProjectsCategoriesData(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Handle filter
  const [filter, setFilter] = useState("all");
  const handleFilter = (e) => {
    e.preventDefault();
    e.target.classList.add("active");
    const projectsGrid = document.querySelector(".projects-list");
    projectsGrid.classList.add("loading");

    //remove active class from other buttons
    const buttons = document.querySelectorAll(".filter-btn");
    buttons.forEach((btn) => {
      if (btn !== e.target) {
        btn.classList.remove("active");
      }
    });
    setFilter(e.target.dataset);
    axios
      .get(`${apiURL}projects`, {
        params: { filter_category: e.target.dataset.filter },
      })
      .then((res) => {
        setProjectsData(res.data.projects);
      })
      .then(() => {
        projectsGrid.classList.remove("loading");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div id="projects">
        <div className="filter-bar">
          <ul>
            <li>
              <a
                href="*"
                className="filter-btn active"
                onClick={handleFilter}
                data-filter="*"
              >
                All
              </a>
            </li>
            {projectsCategoriesData &&
              projectsCategoriesData.map((item) => (
                <li key={item.term_id}>
                  <a
                    href={item.slug}
                    data-filter={item.slug}
                    onClick={handleFilter}
                    className="filter-btn"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="projects-list">
          <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
          {
            // Check if there is projects
            projectsData.length > 0 ? (
              projectsData.map((item) => (
                <div className="col" key={item.id}>
                  <ProjectCard item={item} />
                </div>
              ))
            ) : (
              <div className="no-projects">
                <h2>No projects found</h2>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default ProjectsFilter;
