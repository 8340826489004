import React from "react";
import ContactForm from "./ContactForm";
import cupImg from "../assets/cup-big.png";
import monitorImg from "../assets/monitor-img.png";
import SocialMedia from "./SocialMedia";

const Footer = () => {
  return (
    <footer id="site-footer">
      <div className="container">
        <div className="col deco-imgs">
          <img src={monitorImg} alt="decoImg" />
          <img src={cupImg} alt="decoImg" />
        </div>
        <ContactForm />

        <div className="col social-bar">
          <SocialMedia listType="full" />
        </div>
      </div>
      <div className="copy-bar">
        <p>Copyright © 2022 hNikoloski All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
