import About from './components/About';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Particles from './components/Particles';
import Preloader from './components/Preloader';
import { useEffect } from 'react';
import MyExperience from './components/MyExperience';
import ProjectsFilter from './components/ProjectsFilter';
import { unmountComponentAtNode } from 'react-dom';
function App() {
  useEffect(() => {
    const preloader = document.getElementById('preloader');
    window.addEventListener('load', () => {
      setTimeout(() => {
        preloader.classList.add('preloader--unmount');
      }, 500);
    });
    setTimeout(() => {
      preloader.remove();
    }
      , 3000);
  }, []);
  return (
    <>
      <Preloader />
      <Particles />
      <Header />
      <Hero />
      <About />
      <MyExperience />
      <ProjectsFilter />
      <Footer />

    </>
  );
}

export default App;
